<template>
  <div class="download">
    <img
      class="logo"
      src="../assets/icon/share/logo.png"
    />
    <p class="app-name">Meta彼岸</p>
    <p class="tips">注册成功！</p>
    <div class="main">
      <div class="vr">
        <div class="name">VR端下载</div>
        <div class="content">
          <p>PicoVR助手，搜索“Meta彼岸”下载</p>
          <img src="../assets/icon/vr.png" />
          <p>PicoVR助手</p>
        </div>
      </div>
      <div class="action">
        <div class="name">手机端下载</div>
        <div class="content">
          <div class="btns">
            <div
              v-if="deviceIsAndroid"
              class="btn android"
              @click="download('android')"
            >
              <img
                src="../assets/icon/android.png"
                alt=""
              >
              <p>安卓版下载</p>
            </div>
            <div
              v-else
              class="btn ios"
              @click="download('ios')"
            >
              <img
                src="../assets/icon/apple.png"
                alt=""
              >
              <p>IOS版敬请期待</p>
            </div>
          </div>
          <p>或各大应用商店搜索“Meta 彼岸”</p>
        </div>
      </div>
    </div>
    <div
      class="explore"
      v-if="show"
    >
      <div class="tops">
        <p>点击右上角按钮，然后在弹出的菜单中，点击在浏览器中打开，即可下载安装。</p>
        <img
          src="../assets/icon/share.png"
          alt=""
        >
      </div>
    </div>
  </div>
</template>
<script>
import { Dialog } from 'vant';
import 'vant/lib/dialog/style'
export default {
  data () {

    return {
      show: false,
      deviceIsAndroid: false
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  created () {
    if (this.isWechat()) {
      this.show = true
    }
  },
  methods: {
    isWechat () {
      return (
        /micromessenger/i.test(navigator.userAgent) ||
        typeof WeixinJSBridge !== "undefined"
      );
    },

    /**
     * 检测是否为Android，返回版本号
     * @return {String} [版本号]
     */
    isAndroid () {
      var android = navigator.userAgent.match(/(Android);?[\s\/]+([\d.]+)?/);
      if (android) {
        return android[2];
      } else {
        return "";
      }
    },

    isIos () {
      return (
        navigator.userAgent.indexOf("iPhone") > -1 ||
        navigator.userAgent.indexOf("iPad") > -1
      );
    },
    download (type) {
      if (this.isWechat()) {

        Dialog.alert({
          confirmButtonColor: '#7C75FF',
          message: '点击右上角按钮，然后在弹出的菜单中，点击在浏览器打开，即可安装',
        }).then(() => {
          // on close
        });
      } else {
        if (type == 'android') {
          if (this.isAndroid()) {
            window.location.href = 'https://img.cyber.genimous.com/faramita_android.apk'
          }
        } else {
          this.$toast.fail('IOS版敬请期待')
          if (this.isIos()) {
            // window.location.href = 'http://m.appchina.com/down/u1934'
          }
        }
      }
    }
  },
}
</script>